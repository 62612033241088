<template>
  <div>
    <vx-card title="Medical Details" class="mt-base">
      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/3 my-2">
          <h6>Allergies</h6>
          <ul
            v-if="allergies.length > 0"
            style="list-style: disc; padding-left: 16px"
          >
            <li v-for="(allergy, index) in allergies" :key="index">
              {{ allergy }}
            </li>
          </ul>
          <p v-else class="na">N/A</p>
        </div>

        <div class="w-full lg:w-1/3 my-2">
          <h6>Regular Medications</h6>
          <ul
            v-if="regularMedications.length > 0"
            style="list-style: disc; padding-left: 16px"
          >
            <li v-for="(rm, index) in regularMedications" :key="index">
              {{ rm }}
            </li>
          </ul>
          <p v-else class="na">N/A</p>
        </div>

        <div class="w-full lg:w-1/3 my-2">
          <h6>Previous Medical Diagnoses</h6>
          <ul
            v-if="previousMedicalDiagnoses.length > 0"
            style="list-style: disc; padding-left: 16px"
          >
            <li v-for="(pmd, index) in previousMedicalDiagnoses" :key="index">
              {{ pmd }}
            </li>
          </ul>
          <p v-else class="na">N/A</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxList from "@/components/vx-list/VxList";

export default {
  name: "MedicalDetail",
  props: ["allergies", "regularMedications", "previousMedicalDiagnoses"],
  components: {
    VxList,
  },
};
</script>

<style scoped>
p.na {
  font-weight: normal;
}
</style>

